import React from 'react';
import { PDFViewer, Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../Assets/logo.png';

export const Certificado = () => {
    const styles = StyleSheet.create({
        page: {
          flexDirection: 'row', // Define a direção da página como paisagem
          backgroundColor: '#FFF', // Cor de fundo branca
        },
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1,
        },
        title: {
          fontSize: 24,
          textAlign: 'center',
          marginBottom: 10,
        },
        content: {
          fontSize: 16,
          textAlign: 'center',
          marginBottom: 5,
        },
        logo: {
          width: 100, // Largura do logotipo
          height: 100, // Altura do logotipo
          position: 'absolute', // Define a posição absoluta
          right: 10, // Margem direita
          bottom: 10, // Margem inferior
        },
        greenBorder: {
            borderColor: 'green', // Cor da borda verde
        },
        yellowBorder: {
            borderColor: 'yellow', // Cor da borda amarela
        },
        blueBorder: {
            borderColor: 'blue', // Cor da borda azul
        },
    });

    return (
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>                   
                    <View style={[styles.section, styles.greenBorder]}> {/* Adiciona a borda verde */}
                        <Text style={styles.title}>Certificado</Text>
                        <Text style={styles.content}>O INBRAVISA - Instituto Brasileiro de Auditoria em Vigilância Sanitária Certifica que</Text>
                        <Text style={styles.content}>Vitor Dammenhain</Text>
                        <Text style={styles.content}>concluiu com sucesso o curso.</Text>
                    </View>
                    <Image src={logo} style={styles.logo} /> {/* Logotipo */}
                </Page>
            </Document>
        </PDFViewer>
    );
}