import React, { useEffect, useState } from "react";
import api from "../../config/configAPI";
import { Link } from 'react-router-dom';
import { FaShoppingCart, FaInfoCircle } from 'react-icons/fa';

export const Card = () => {
    const token = localStorage.getItem('token');

    const [data, setData] = useState([]);

    const [server, setServer] = useState({
        type: '',
        mensagem: ''
    });

    const getUsers = async () => {
        await api.get("/Protocolos/")
            .then((response) => {
                console.log(response);
                setData(response.data.users);
            }).catch((err) => {
                if (err.response) {
                    setServer({
                        type: 'Erro',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setServer({
                        type: 'Erro',
                        mensagem: 'Conexão com o servidor perdida'
                    });
                }
            });
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <div className="flex flex-wrap justify-center">
        {data.map(user => (
            <div className="p-7 m-5 w-96 h-auto border-2 border-gray-300 shadow-lg hover:bg-gray-100 hover:text-black duration-300 flex flex-col justify-between" key={user.id}>
                <div>
                    <p className="text-center text-xl font-bold">{user.Nome}</p>
                </div>
                <div>
                    <img className="object-cover h-48 w-full" src={user.LinkImagem} alt={user.Nome} />
                </div>
                <div className="flex-1">
                    <p className="text-center">
                        {user.Descricao.split('\n').map((str, index) => (
                            <React.Fragment key={index}>
                                {str}
                                <br />
                            </React.Fragment>
                        ))}
                    </p>
                </div>
                <div className="mt-4">
                    <div className="flex justify-between items-center">
                        <Link to={"/Protocolo/" + user.id}>
                            <button className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md mr-2" type="button">
                                <FaInfoCircle size={20} />
                                <span>Detalhes</span>
                            </button>
                        </Link>
                        {user.Promocao !== '1' ? (
                            <div className="text-center">
                                <p className="text-lg font-bold">R${user.Valor},00</p>
                            </div>
                        ) : (
                            <div className="text-center">
                                <p className="text-red-600 line-through">R${user.Valor},00</p>
                                <p className="text-green-600 font-bold">R${user.Valor_Desconto},00</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-4">
                    {token ? (
                        <Link to={"/Checkout/" + user.id}>
                            <button className="flex items-center justify-center w-full bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md" type="button">
                                <FaShoppingCart size={20} />
                                <span>Comprar</span>
                            </button>
                        </Link>
                    ) : (
                        <Link to={"/Login/" + user.id}>
                            <button className="flex items-center justify-center w-full bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md" type="button">
                                <FaShoppingCart size={20} />
                                <span>Comprar</span>
                            </button>
                        </Link>
                    )}
                </div>
            </div>
        ))}
    </div>
    );
};