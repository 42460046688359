import React, { useEffect, useState } from "react";
import api from "../config/configAPI";






export const FiltroAssistida = ({ num }) => {


    const [assistida, setAssistida] = useState([]);

    const [server2, setServer2] = useState({
        type: '',
        mensagem: ''
    })

    //Listar Quantidade Aulas por Id de Aluno e Id de Aula
    const AulaAssistida = async () => {
        await api.get("/Assistidas/" + localStorage.getItem('idUser') + "/" + num)
            .then((response) => {
                console.log(response);
                setAssistida(response.data.user)
            }).catch((err) => {
                if (err.response) {
                    //console.log(err.response.data.mensagem);
                    setServer2({
                        type: 'Erro',
                        mensagem: err.response.data.mensagem
                    })
                } else {
                    //console.log("ERRO: Tente mais tarde!");
                    setServer2({
                        type: 'Erro',
                        mensagem: 'Conexão com o servidor perdida'
                    })
                }
            });

    }


    useEffect(() => {
        AulaAssistida();
    }, [])



    return (
        <span>

            {
                assistida.count > 0 ? (
                    <div>
                        <span className="rounded-full bg-emerald-800 text-white pr-2 pl-2">
                            {assistida.count}
                        </span>

                        <a href='/Certificado'> Ver certificado</a>


                    </div>
                )
                    :
                    (<span className="rounded-full bg-red-800 text-white pr-2 pl-2">
                        Não Assistida
                    </span>
                    )
            }


        </span>

    )
}