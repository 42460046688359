import React from "react";




export const Jumbo = ()=>{
    return(
        <div className="md:flex md:flex-row flex flex-col items-center">
            <img className="md:mr-10 md:w-[10%] md:max-w-none md:h-auto w-44 h-44" src="http://www.inbravisa.com.br/imagens/logo%20INBRAVISA_FB%20(13).jpg"></img>
            <br/>
            <p className="flex text-xl align-middle text-justify" >
                Os Protocolos Técnicos Sanitários oferecidos pelo INBRAVISA - Instituto Brasileiro de Auditoria em Vigilância Sanitária, 
                foram desenvolvidos por profissionais qualificados com anos de atuação na área regulatória, 
                com a intenção de promover conhecimento aos mais diversos tipos de serviços de saúde.
            </p>
            <p>

            </p>  
        </div>
    )
}